/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';
import ImageTemplate from '@components/image/image-src.twig';
import { ResizeObserver } from '@juggle/resize-observer';

const indexedListBehavior = createBehavior(
  'indexed-list',
  {
    bindUI() {
      this.ui = {};
      this.ui.nav = this.getChild('nav');
      this.ui.navInner = this.getChild('nav-inner');
      this.ui.navTopSensor = this.getChild('nav-top-sensor');
      this.ui.navItems = this.getChildren('nav-item');
      this.ui.navBottomSensor = this.getChild('nav-bottom-sensor');
      this.ui.items = this.getChildren('item');
      this.ui.imageContainer = this.getChild('image-container');
      this.ui.image = this.getChild('image');
      this.ui.imageTopSensor = this.getChild('image-top-sensor');
      this.ui.imageBottomSensor = this.getChild('image-bottom-sensor');
    },
    readSensorPositions() {
      // Navigation
      let navTopSensorRect = this.ui.navTopSensor.getBoundingClientRect();
      let navBottomSensorRect = this.ui.navBottomSensor.getBoundingClientRect();
      this.isNavSticking =
        navTopSensorRect.top < 0 && this.sidebarHeight < this.nodeHeight;
      this.isNavStuckBottom =
        navBottomSensorRect.bottom < this.windowHeight &&
        this.sidebarHeight < this.nodeHeight;

      // Image container
      let imageTopSensorRect = this.ui.imageTopSensor.getBoundingClientRect();
      let imageBottomSensorRect =
        this.ui.imageBottomSensor.getBoundingClientRect();
      this.isImageSticking =
        imageTopSensorRect.top < 0 && this.imageHeight < this.nodeHeight;
      this.isImageStuckBottom =
        imageBottomSensorRect.bottom < this.windowHeight &&
        this.imageHeight < this.nodeHeight;
    },
    handleWindowScroll() {
      this.readSensorPositions();
      // https://stackoverflow.com/questions/41740082/scroll-events-requestanimationframe-vs-requestidlecallback-vs-passive-event-lis
      requestAnimationFrame(this.updateUI);
    },
    handleWindowResize() {
      this.cacheDimensions();
      this.readSensorPositions();
      requestAnimationFrame(this.updateUI);
    },
    bindEvents() {
      window.addEventListener('scroll', this.handleWindowScroll);
      window.addEventListener('resize', this.handleWindowResize);
      this.sidebarResizeObserver = new ResizeObserver((entries) => {
        this.cacheDimensions();
        this.readSensorPositions();
        requestAnimationFrame(this.updateUI);
      });
      this.sidebarResizeObserver.observe(this.ui.nav);

      for (let item of this.ui.items) {
        item.addEventListener('mouseover', this.handleItemMouseover);
        item.addEventListener('mouseout', this.handleItemMouseout);
      }

      for (let navItem of this.ui.navItems) {
        navItem.addEventListener('click', this.handleNavItemClick);
      }
    },
    cacheDimensions() {
      this.nodeHeight = this.node.offsetHeight;
      this.sidebarHeight = this.ui.navInner.offsetHeight;
      this.sidebarWidth = this.ui.nav.offsetWidth;
      this.imageWidth = this.ui.imageContainer.offsetWidth;
      this.imageHeight = this.ui.image.offsetHeight;
      this.windowHeight = window.innerHeight;
    },
    updateUI() {
      this.ui.nav.classList.toggle('is-sticking', this.isNavSticking);
      this.ui.nav.classList.toggle('is-stuck-bottom', this.isNavStuckBottom);

      this.ui.imageContainer.classList.toggle(
        'is-sticking',
        this.isImageSticking,
      );
      this.ui.imageContainer.classList.toggle(
        'is-stuck-bottom',
        this.isImageStuckBottom,
      );

      // Nav
      let navDiff = (this.windowHeight - this.sidebarHeight) / 2;
      this.ui.navTopSensor.style.top = `${0 - navDiff}px`;
      this.ui.navBottomSensor.style.bottom = `${0 - navDiff}px`;
      this.ui.navInner.style.width = `${this.sidebarWidth}px`;

      let imageDiff = (this.windowHeight - this.imageHeight) / 2;
      this.ui.imageTopSensor.style.top = `${0 - imageDiff}px`;
      this.ui.imageBottomSensor.style.bottom = `${0 - imageDiff}px`;
      this.ui.image.style.width = `${this.imageWidth}px`;
      this.ui.image.style.height = `${this.imageWidth}px`;
    },
    unbindEvents() {
      window.removeEventListener('scroll', this.handleWindowScroll);
      window.removeEventListener('resize', this.handleWindowResize);
      this.sidebarResizeObserver.unobserve(this.ui.nav);
    },
    handleItemMouseover(evt) {
      this.currentItem = evt.currentTarget;
      let target = evt.currentTarget;

      let { image } = JSON.parse(evt.currentTarget.dataset.indexedListItem);

      let imageHTML = ImageTemplate({
        ...image,
        sources: {
          [image.role]: {
            default: image.sources[image.role].square,
            ...image.sources[image.role],
          },
        },
        sizes: image.sizes.map((size) => ({
          crop: size.crop,
          width: size.breakpoints[size.breakpoints.length - 1].width,
        })),
        lazy: false,
      });

      let imageObj = new Image();
      imageObj.src = image.src;

      let imageLoadedPromise = new Promise((resolve, reject) => {
        if (imageObj.complete) {
          resolve();
        } else {
          let handleLoad = () => {
            imageObj.removeEventListener('load', handleLoad);
            resolve();
          };
          imageObj.addEventListener('load', handleLoad);
        }
      });

      let promises = [imageLoadedPromise];
      if (this.visibleEndPromise) {
        promises.push(this.visibleEndPromise);
      }

      Promise.all(promises).then(() => {
        // Check this promise is still valid...
        if (this.currentItem === target && this.currentItem) {
          this.ui.image.innerHTML = imageHTML;
          this.ui.image.classList.add('is-visible');
        }
      });
    },
    handleItemMouseout() {
      this.currentItem = null;
      if (this.ui.image.classList.contains('is-visible')) {
        this.visibleEndPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, 600);
        });
        this.ui.image.classList.remove('is-visible');
      }
    },
    handleNavItemClick(evt) {
      evt.preventDefault();
      let el = document.getElementById(
        evt.currentTarget.getAttribute('href').slice(1),
      );
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
  },
  {
    init() {
      this.previousTopY = 0;
      this.previousBottomY = 0;
      this.sidebarWidth = 0;
      this.currentItem = null;
      this.isNavSticking = false;
      this.isNavStuckBottom = false;
      this.isImageSticking = false;
      this.isImageStuckBottom = false;
      this.visibleEndPromise = null;
      this.bindUI();
      this.bindEvents();
      this.cacheDimensions();
      this.updateUI();
    },
    destroy() {
      this.unbindEvents();
    },
  },
);

export default indexedListBehavior;
