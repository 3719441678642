import createBehavior from '@js/functions/createBehavior.js';
import loadScript from '@js/functions/load-script.js';

const chatbotBehavior = createBehavior(
  'chatbot',
  {
    hideChatbot() {
      setTimeout(() => {
        const chatbotWrapper = document.querySelector('.askmona-webchat-widget');
        const iframe = document.querySelector('iframe[src*="webchat.askmonastudio.com"]');
        console.log('hiding chatbot');

        if (chatbotWrapper) {
          chatbotWrapper.style.display = 'none';
        }
        if (iframe && iframe.parentElement) {
          iframe.parentElement.style.display = 'none';
        }
      }, 1000);
    },

    unhideChatbot() {
      const chatbotWrapper = document.querySelector('.askmona-webchat-widget');
      const iframe = document.querySelector('iframe[src*="webchat.askmonastudio.com"]');

      if (chatbotWrapper) {
        chatbotWrapper.style.display = 'block';
      }
      if (iframe && iframe.parentElement) {
        iframe.parentElement.style.display = 'block';
      }
    },

    handleMobileVisibility() {
      const isMobile = window.innerWidth < 768;

      if (isMobile) {
        this.hideChatbot();
      } else {
        this.unhideChatbot();
      }
    },

    loadChatSDK() {
      return loadScript('https://webchat.askmonastudio.com/scripts/embed.mjs').then((data) => {
        const url = new URL(window.location.href);
        Askmona.start({
          wat: this.options['app-id'],
        });
        this.observeIframeCreation();
        return data;
      });
    },

    observeIframeCreation() {
      const observer = new MutationObserver((mutations) => {
        if (document.querySelector('iframe[src*="webchat.askmonastudio.com"]')) {
          this.handleMobileVisibility();
          observer.disconnect();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    },
  },
  {
    init() {
      this.hideChatbot = this.hideChatbot.bind(this);
      this.unhideChatbot = this.unhideChatbot.bind(this);
      this.handleMobileVisibility = this.handleMobileVisibility.bind(this);
      this.observeIframeCreation = this.observeIframeCreation.bind(this);
      const isMobile = window.innerWidth < 768;

      const iframe = document.querySelector('iframe[src*="webchat.askmonastudio.com"]');

      if (!iframe && !isMobile) {
        this.loadChatSDK();
      } else if(iframe) {
        this.unhideChatbot();
      }

      window.addEventListener('resize', this.handleMobileVisibility);
      document.addEventListener('nav:opened', this.hideChatbot);
      document.addEventListener('nav:closed', this.unhideChatbot);
    },

    destroy() {
      window.removeEventListener('resize', this.handleMobileVisibility);
      document.removeEventListener('nav:opened', this.hideChatbot);
      document.removeEventListener('nav:closed', this.unhideChatbot);
    },
  }
);

export default chatbotBehavior;
